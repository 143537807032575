<template>
  <Form :model="worker.addArchiveAssessRo" :inline="true" :label-colon="true">
    <FormItem label="社工评估" style="width: 100%">
      <FormItem style="width: calc(100% - 54px);margin: 0 26px">
          <Checkbox v-model="worker.addArchiveAssessRo.second" true-value="1" false-value="2" @on-change="clearInputVal('addArchiveAssessRo','secondItem')"  style="width: 100%;margin-bottom: 10px">
              二次探访
              <span v-if="worker.addArchiveAssessRo.second == '1'">(进行二次探访的主要事项
            <Input :maxlength="20" v-model.trim="worker.addArchiveAssessRo.secondItem"></Input>
            )
            </span>
            </Checkbox>
          <Checkbox v-model="worker.addArchiveAssessRo.regular" true-value="1" false-value="2" @on-change="clearInputVal('addArchiveAssessRo','regularItem')">
              定期探访/电话访问
          </Checkbox>
           <Select v-model="worker.addArchiveAssessRo.regularCycle" style="width:200px" v-if="worker.addArchiveAssessRo.regular=='1'">
                    <Option v-for="item in regularCycleList" :key="item.dictKey" :value="item.dictKey">{{item.dictValue}}</Option>
            </Select>
              <Checkbox v-model="worker.addArchiveAssessRo.referral" true-value="1" false-value="2" style="width: 100%;">转介</Checkbox>
              <Checkbox v-model="worker.addArchiveAssessRo.startCase" true-value="1" false-value="2" style="width: 100%;">开案（专业个案、咨询个案）</Checkbox>
              <Checkbox v-model="worker.addArchiveAssessRo.team" true-value="1" false-value="2" style="width: 100%;">小组/活动</Checkbox>
              <Checkbox v-model="worker.addArchiveAssessRo.follow" true-value="1" false-value="2" @on-change="clearInputVal('addArchiveAssessRo','followCause')" style="width: 100%;margin-bottom: 10px">
              不需要跟进<span v-if="worker.addArchiveAssessRo.follow == '1'">（原因
            <Input :maxlength="20" v-model.trim="worker.addArchiveAssessRo.followCause"></Input>
            )</span>
            </Checkbox>
         <FormItem label="危机因素" style="width: 100%;">
        <RadioGroup v-model="worker.addArchiveAssessRo.crisis" @on-change="worker.addArchiveAssessRo.crisisLevel='';clearInputVal('addArchiveAssessRo','crisisCause')">
            <Radio label="2" style="margin-right: 20px">无</Radio>
            有
        </RadioGroup>
        <RadioGroup v-model="worker.addArchiveAssessRo.crisisLevel" @on-change="worker.addArchiveAssessRo.crisis='1'">（<Radio label="3">高</Radio>
            <Radio label="2">中</Radio>
            <Radio label="1">低</Radio>
            ）</RadioGroup>
            <span v-if="worker.addArchiveAssessRo.crisis == '1'">（请注明: <Input v-model="worker.addArchiveAssessRo.crisisCause" style="width: calc(100% - 400px)"></Input>）
            </span>
        </FormItem>
      </FormItem>
    </FormItem>
     <FormItem label="处理跟进工作" style="width: 100%">
      <FormItem style="width: calc(100% - 54px);margin: 0 26px">
          <Checkbox v-model="worker.addArchiveFollowRo.meetSelf" true-value="1" false-value="2">
              安排与案主会面/电话访问
            </Checkbox>
          <Checkbox v-model="worker.addArchiveFollowRo.meetRelated" true-value="1" false-value="2"  @on-change="clearInputVal('addArchiveFollowRo','meetRelatedDesc')" style="width: 100%;margin-bottom: 10px">
              安排与相关人员会面 
              <span v-if="worker.addArchiveFollowRo.meetRelated == '1'">(请注明：
            <Input v-model="worker.addArchiveFollowRo.meetRelatedDesc"></Input>
            )</span>
          </Checkbox>
          <Checkbox v-model="worker.addArchiveFollowRo.delegateFollow" true-value="1" false-value="2" @on-change="clearInputVal('addArchiveFollowRo','followStaff')" style="width: 100%;margin-bottom: 10px">
              委派其他社工跟进 
              <span v-if="worker.addArchiveFollowRo.delegateFollow== '1'">(跟进人：
            <Input v-model="worker.addArchiveFollowRo.followStaff"></Input>
            )</span>
          </Checkbox>
          <Checkbox v-model="worker.addArchiveFollowRo.referral" true-value="1" false-value="2" @on-change="clearInputVal('addArchiveFollowRo','referralDesc')" style="width: 100%;margin-bottom: 10px">
              转介其他单位跟进 
              <span v-if="worker.addArchiveFollowRo.referral== '1'">(请注明：
            <Input v-model="worker.addArchiveFollowRo.referralDesc"></Input>
            )</span>
          </Checkbox>
          <Checkbox v-model="worker.addArchiveFollowRo.followNot" true-value="1" false-value="2" @on-change="clearInputVal('addArchiveFollowRo','followNotDesc')" style="width: 100%;margin-bottom: 10px">
              不适合跟进 
              <span v-if="worker.addArchiveFollowRo.followNot== '1'">(原因：
            <Input v-model="worker.addArchiveFollowRo.followNotDesc"></Input>
            )</span>
          </Checkbox>
      </FormItem>
     </FormItem>
  </Form>
</template>

<script>
export default {
  props: ["worker"],
  data() {
    return {regularCycleList:[]};
  },
  created() {
    this.$get('/datamsg/api/common/sys/findDictByDictType',{
      dictType: "REGULAR_CYCLE",
    }).then(res => {
      if(res.code == 200 && res.dataList) {
        this.regularCycleList = res.dataList;
      }else {
        this.$Message.error({
          background: true,
          content: res.desc
        })
      }
    })
  },
  methods: {
    //改变复选框状态，清空input的值
    clearInputVal(obj,name) {
      this.worker[obj][name] = '';
    }
  }
};
</script>

<style scoped lang="less">
.ivu-form-item {
  width: 100%;
  margin-bottom: 15px;
  .ivu-input-wrapper {
    width: calc(100% - 400px);
    /deep/input {
      border: 0;
      border-bottom: 1px solid #65656c;
      border-radius: 0;
      width: 100%;
    }
    /deep/input:hover,
    /deep/input:focus {
      border: 0;
      border-bottom: 1px solid #65656c;
      box-shadow: none;
    }
  }
}
</style>
